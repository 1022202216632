import {
  ImageLoader,
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from 'next/image'

import { ImageProps } from '.'

export function useImageLoader({
  url,
  proxy,
  focalPointX,
  focalPointY,
  width,
  height,
  fit,
}: Pick<
  ImageProps,
  'url' | 'proxy' | 'focalPointX' | 'focalPointY' | 'width' | 'height' | 'fit'
>): Pick<NextImageProps, 'src' | 'loader'> {
  let src = url
  if (typeof url === 'string') {
    if (url.startsWith('blob:')) {
      // Do not proxy images from blobs (when previewing client-side images about
      // to be uploaded etc.)
      proxy = undefined
    } else if (!url.startsWith('http') && proxy !== 'LOCAL') {
      src = `https://landfolk.imgix.net/${url}`
      proxy = 'IMGIX'
    } else if (!proxy && url.includes('imgix')) {
      proxy = 'IMGIX'
    }
  }

  const aspect = height ? Number(height) / Number(width) : undefined

  const focalPoint =
    focalPointX && focalPointY ? { x: focalPointX, y: focalPointY } : undefined

  const loader = proxy
    ? createLoader(proxy, aspect, focalPoint, fit)
    : undefined

  return {
    src,
    loader,
  }
}

export const createLoader =
  (
    proxy: ImageProps['proxy'],
    aspect?: number,
    focalPoint?: { x: number; y: number },
    fit?: ImageProps['fit'],
  ): ImageLoader =>
  (args) => {
    switch (proxy) {
      case 'IMGIX':
        // Next/image loaders only receive the width of the requested image out of the
        // box. This workaround passes appropriate aspect to imgixLoader also, so we
        // can calculate the proper height there.
        // See: https://github.com/vercel/next.js/discussions/22050
        return imgixLoader({ ...args, aspect, focalPoint, fit })
      case 'LOCAL':
        return args.src
      default:
        return args.src
    }
  }

export function imgixLoader({
  src,
  width,
  aspect,
  quality,
  focalPoint,
  fit,
}: Omit<ImageLoaderProps, 'config'> & {
  aspect?: number
  focalPoint?: {
    x: number
    y: number
  }
  fit?: ImageProps['fit']
}): string {
  // Parse the src url so we can read its parts and add our own parts
  const url = new URL(src)

  // Demo: https://static.imgix.net/daisy.png?format=auto&fit=max&w=300
  url.searchParams.append('auto', 'compress,format')
  url.searchParams.append('cs', 'srgb')
  url.searchParams.append(
    'fit',
    typeof fit !== 'undefined' ? fit : aspect ? 'crop' : 'max',
  )

  if (focalPoint) {
    url.searchParams.append('fp-x', focalPoint.x.toString())
    url.searchParams.append('fp-y', focalPoint.y.toString())
    url.searchParams.append('crop', 'focalpoint')
  }

  if (width) {
    url.searchParams.append('w', width.toString())
  }

  if (aspect) {
    url.searchParams.append('h', Math.round(Number(width) * aspect).toString())
  }

  if (quality) {
    url.searchParams.append('q', quality.toString())
  }

  return normalizeSrc(url.toString())
}

function normalizeSrc(src: string): string {
  return src[0] === '/' ? src.slice(1) : src
}
