import Image from '@landfolk/shared/components/Image'
import Link from 'next/link'

export default function Home() {
  return (
    <>
      <h1 tx="mb-4 mt-3 font-serif text-f2.5 lg:(mb-4.5 mt-4 text-f2)">
        Landfolk Design System
      </h1>
      <div tx="max-w-prose">
        <p tx="mb-3 text-f4 lg:(text-f3 leading-8)">
          It’s important that Landfolk appears recognizable on all platforms and
          all over the world.
        </p>
        <p>
          We’ve named our identity LDS – Landfolk Design System. In addition to
          this page, which contains components for use on landfolk.com, you can{' '}
          <Link href="Landfolk_Design_Guidelines_v1.pdf">
            download the guidelines describing use of logo, colors, typography
            etc. as a PDF here
          </Link>
          .
        </p>
      </div>
      <div tx="mt-4.5 grid max-w-prose grid-cols-6 gap-1 border-t border-february-tertian pt-4 lg:items-center">
        <div tx="col-span-2 grid grid-cols-4 gap-x-3 gap-y-2">
          <div tx="nl:(col-span-2 col-start-2)">
            <Image
              tx="rounded-full"
              url="/team/chris.jpg"
              width={300}
              height={300}
              alt="Chris"
            />
          </div>
          <div tx="col-span-full text-f7 lg:(col-span-5 col-start-2 self-center) nl:text-center">
            <span tx="font-bold">
              <Link href="mailto:chris@landfolk.com">Chris Kjær Sørensen</Link>
            </span>
            <br />
            <span tx="text-february-muted">CTO</span>
          </div>
        </div>
        <div tx="col-span-2 col-start-3 grid grid-cols-4 gap-x-3 gap-y-2">
          <div tx="nl:(col-span-2 col-start-2)">
            <Image
              tx="rounded-full"
              url="/team/lars.jpg"
              width={300}
              height={300}
              alt="Lars"
            />
          </div>
          <div tx="col-span-full text-f7 lg:(col-span-5 col-start-2 self-center) nl:text-center">
            <span tx="font-bold">
              <Link href="mailto:lars@landfolk.com">Lars Kloster Silkjær</Link>
            </span>
            <br />
            <span tx="text-february-muted">Head of Design</span>
          </div>
        </div>
        <div tx="col-span-2 col-start-5 grid grid-cols-4 gap-x-3 gap-y-2">
          <div tx="nl:(col-span-2 col-start-2)">
            <Image
              tx="rounded-full"
              url="https://landfolk-storage.imgix.net/eped6wzh6g871blghj1ak29exu3j"
              width={300}
              height={300}
              alt="Kaya"
            />
          </div>
          <div tx="col-span-full text-f7 lg:(col-span-5 col-start-2 self-center) nl:text-center">
            <span tx="font-bold">
              <Link href="mailto:kaya.olsen@landfolk.com">Kaya Olsen</Link>
            </span>
            <br />
            <span tx="text-february-muted">UX Writer</span>
          </div>
        </div>
      </div>
    </>
  )
}
